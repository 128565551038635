<template>
    <div style="height: calc(100vh - 80px)">
        <div class="card card-body m-3 p-0 overflow-hidden" style="height: 100%">
            <splitpanes class="default-theme file-manager">
                <pane min-size="20" size="20">
                    <div class="fm-header border-bottom d-flex">
                        <div class="flex-grow-1 pr-3">Папкалар</div>

                        <div class="d-flex" style="grid-gap: 10px">
                            <div class="cursor-pointer" @click="showCreateDirectoryModal()">
                                <i class="mdi mdi-folder-plus"></i>
                            </div>

                            <div class="cursor-pointer" @click="showCreateFileDialog()">
                                <i class="mdi mdi-file-plus"></i>
                            </div>
                        </div>
                    </div>

                    <folder-tree-item v-if="folders['/']" :item="rootFolder" class="bg-white" />
                </pane>
                <pane min-size="50" style="overflow: scroll">
                    <div class="fm-header border-bottom d-flex">
                        <div class="flex-grow-1 pr-3">Папкадаги файллар {{ activeFolder }}</div>

                        <div class="d-flex" style="grid-gap: 20px">
                            <div class="cursor-pointer" @click="showCreateDirectoryModal(activeFolder)">
                                <i class="mdi mdi-folder-plus"></i>
                                Янги папка
                            </div>

                            <div class="cursor-pointer" @click="showCreateFileDialog(activeFolder)">
                                <i class="mdi mdi-file-plus"></i>
                                Янги файл
                            </div>
                        </div>
                    </div>

                    <file-table :items="activeFolderFiles" class="bg-white" />
                </pane>
            </splitpanes>
        </div>
    </div>
</template>

<script>
import { Pane, Splitpanes } from "splitpanes"
import "splitpanes/dist/splitpanes.css"

import FileTable from "./FileTable.vue"
import FileManagerDialogsMixin from "./FileManagerDialogsMixin"
import pathJoin from "./pathJoin"
import FolderTreeItem from "@Platon/components/file-manager/FolderTreeItem.vue"
import { getUser } from "@Platon/core/core"
import { API_BASE_URL } from "@Platon/const"

export default {
    name: "FileManager",
    components: { FolderTreeItem, FileTable, Splitpanes, Pane },

    mixins: [FileManagerDialogsMixin],

    beforeRouteEnter(to, from, next) {
        const user = getUser()

        if (user && Array.isArray(user.permissions) && user.permissions.includes("platon.admin")) {
            next()
        } else {
            next("/")
        }
    },

    provide() {
        return {
            fm: this
        }
    },

    data() {
        return {
            folders: {},
            state: {},
            activeFolder: "/"
        }
    },

    created() {
        this.initFolder("/")
        this.loadFolderData()
    },

    computed: {
        activeFolderFiles() {
            return (this.folders[this.activeFolder] || []).filter((f) => f.type === "FILE")
        },

        /**
         * @return PlatonFMFolder
         */
        rootFolder() {
            return {
                name: "/",
                path: "",
                size: 0,
                type: "DIRECTORY",
                children: this.folders["/"]
            }
        }
    },

    methods: {
        initFolder(path) {
            if (!this.state[path]) this.$set(this.state, path, {})
        },

        async loadFolderData(path = "/") {
            this.$set(this.state[path], "isLoading", true)
            const url = ("/web/v1/store/list/" + path).replace(/\/\/+/g, "/")

            try {
                const response = await this.$api.get(url, {
                    baseURL: API_BASE_URL
                })

                this.$set(this.folders, path, response.data.data.results)
                this.$set(this.state[path], "isOpen", true)
                this.$set(this.state[path], "isLoaded", true)

                response.data.data.results.forEach((item) => {
                    this.initFolder(pathJoin(item.path, item.name))
                })
            } finally {
                this.$set(this.state[path], "isLoading", false)
            }
        },

        toggleFolder(path) {
            this.$set(this.state[path], "isOpen", !this.state[path].isOpen)
        },

        isOpen(path) {
            return this.state[path].isOpen === true || this.state[path].isOpen === undefined
        },

        addFolder(path, folder) {
            const dir = this.folders[path]
            dir.push(folder)

            this.$set(this.folders, path, dir)

            this.initFolder(pathJoin(folder.path, folder.name))
        },

        addFile(path, folder) {
            const dir = this.folders[path]
            dir.push(folder)

            this.$set(this.folders, path, dir)
        },

        /**
         * @param {string} folder
         * @param {string} deletedFile
         */
        removeFile(folder, deletedFile) {
            /**
             * @type {PlatonFMFile[]}
             */
            const folderContents = this.folders[folder]

            this.$set(
                this.folders,
                folder,
                folderContents.filter((d) => d.name !== deletedFile)
            )
        },

        setActiveFolder(path) {
            this.activeFolder = path
        },

        isActiveFolder(path) {
            return this.activeFolder === path
        }
    }
}
</script>

<style>
.default-theme.file-manager .splitpanes__pane {
    /*background-color: transparent !important;*/
    background-color: var(--pl-file-manager-bg-color) !important;
}

.splitpanes__splitter {
    background-color: var(--pl-file-manager-splitter-border-bg) !important;
}

.fm-header {
    padding: 8px 12px;
}

.bg-white {
    background-color: var(--pl-file-manager-bg-color) !important;
}

.mdi-folder,
.mdi-folder-plus,
.mdi-file-plus {
    color: var(--pl-file-manager-folder-color) !important;
}
</style>
