import FileUploadDialog from "@Platon/components/file-manager/FileUploadDialog.vue"
import pathJoin from "@Platon/components/file-manager/pathJoin"
import { API_BASE_URL } from "@Platon/const"

export default {
	methods: {
		showCreateDirectoryModal(parentFolder = "/") {
			this.promptAction("Папка яратиш", `${parentFolder} да янги папка яратилади<br>Папка номини киритинг`, {
				confirmText: "Яратиш",
				rejectText: "Бекор қилиш"
			}).then(async (folderName) => {
				const folder = await this.createFolder(parentFolder, folderName)

				this._getFileManager().addFolder(parentFolder, folder)
			})
		},

		/**
		 * @param {string} parentFolder
		 * @param {string} newFolderName
		 */
		async createFolder(parentFolder, newFolderName) {
			const filteredFolderName = newFolderName.replace(/\W+/g, "-")
			const url = ("web/v1/store/directory" + parentFolder + "/" + filteredFolderName).replace(/\/\/+/g, "/")

			const response = await this.$api.post(
				url,
				{},
				{
					baseURL: API_BASE_URL
				}
			)

			return response.data
		},

		_getFileManager() {
			return this.fm || this
		},

		/**
		 * @param {string} parentFolder
		 */
		showCreateFileDialog(parentFolder = "/") {
			this.$modal.show(
				FileUploadDialog,
				{
					parentFolder,
					createFile: async (parentFolder, /** FileList */ files) => {
						// GETTING NAMES OF ALL FILES
						let existingFiles = this._getFileManager()._data.folders[parentFolder]
						let names = existingFiles.map((f) => f["name"])

						// GETTING NAMES OF ALREADY EXISTING FILES OR RATHER PUSHING 'EM
						const existences = []

						for (let file of files) {
							if (names.includes(file["name"])) {
								existences.push(file["name"])
							} else {
								const uploadedFile = await this.createFile(parentFolder, file)
								this._getFileManager().addFile(parentFolder, uploadedFile)
							}
						}

						return existences
					}
				},
				{
					width: "450px"
				}
			)
		},

		/**
		 * @param {string} parentFolder
		 * @param {File} file
		 */
		async createFile(parentFolder, file) {
			const form = new FormData()
			form.append("file", file, file.name)

			const response = await this.$api.post("web/v1/store/upload" + parentFolder, form, {
				baseURL: API_BASE_URL
			})

			return response.data
		},

		/**
		 * @param {string} folder
		 * @param {string} path
		 */
		deleteFileDialog(folder, path) {
			this.confirmAction(
				async () => {
					await this.deleteFile(pathJoin(folder, path))

					this._getFileManager().removeFile(folder, path)
				},
				{
					text: `${path} ни ўчиришни хоҳлайсизми ?`
				}
			)
		},

		async deleteFile(path) {
			const response = await this.$api.delete("web/v1/store/info" + path, {
				baseURL: API_BASE_URL
			})

			return response.data
		}
	}
}
